import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Icon } from 'components/Icon';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import Divider from 'components/UI/Divider';
import FlexBox from 'components/UI/Layout/FlexBox';
import Screen from 'components/UI/Layout/Screen';
import Spacer from 'components/UI/Layout/Spacer';
import Spinner from 'components/UI/Spinner/Spinner';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import { Svg } from 'components/UI/Svg';
import { createMessageComposeParamsFromMessageDetails } from 'lib/messaging/utils';
import { Color } from 'modules/styles/colors';
import { BorderRadius, FontSize, FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import { formatPastDisplayDate } from 'modules/utils/DateUtils';
import { inboundMessageCounter, orFallback } from 'modules/utils/StringUtils';
import { objectFromQsParam } from 'modules/utils/UrlUtils';
import React, { ComponentType, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NavigationScreenProps } from 'screens/navigation';
import { CompanyName } from 'modules/utils/ConfigUtils';
import {
  downloadMessageAttachment,
  downloadMessageDetails,
  getMessageById,
  messageReply,
  resetMessageDetail,
  startDirectReply
} from 'store/messaging/actions';
import { MessageDownloadState } from 'store/messaging/reducers/downloads';
import {
  messageDetailDataSelector,
  messageDetailErrorSelector,
  messageDetailFetchingSelector,
  messageDetailsDownloadingSelector,
  messageDetailsDownloadsSelector,
  messageDownloadsSelector
} from 'store/messaging/selectors';
import { MessageAttachment, MessageDetail, MessageFolderType } from 'store/messaging/types';
import { RootState } from 'store/types';
import { MuiBox, MuiButton, MuiContainer, MuiTypography } from 'theme/material-ui';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import getMessageText from './rtf/rtfComponents';
import CallToActionRow from 'components/common/CallToActionRow/CallToActionRow';

interface MessageDetailHeaderProps {
  onClose: () => void;
  message: MessageDetail;
}

const MessageDetailsHeader = (props: MessageDetailHeaderProps) => (
  <MuiBox>
    <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.semibold}>
      {orFallback(props.message.subject, '(No Subject)')}
    </MuiTypography>
    {props.message.attachments && props.message.attachments.length ? (
      <FlexBox alignItems="center" flexDirection="row">
        <MuiBox pr={1} alignSelf="center">
          <Svg set="assets" name="FileAttachment" size={IconSize.small} />
        </MuiBox>
        <MuiTypography>{props.message.attachments.length} attachment(s)</MuiTypography>
      </FlexBox>
    ) : null}
    <MuiBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" py={2}>
      <MuiBox display="flex" flexDirection="row" lineHeight="1.5">
        <MuiBox mr={1}>
          <AccountCircleIcon fill={Color.grayHue2} />
        </MuiBox>
        <MuiTypography variant="subtitle1">
          {props.message.messagePool?.groupDisplayName || CompanyName}
        </MuiTypography>
      </MuiBox>
      <MuiTypography>
        {orFallback(formatPastDisplayDate(props.message.createDate), '--')}
      </MuiTypography>
    </MuiBox>
    <Divider />
  </MuiBox>
);

const MessageDetailsContentAttachment = ({
  attachment,
  onPress,
  loading
}: {
  attachment: MessageAttachment;
  onPress: () => void;
  loading: boolean;
}) => {
  return (
    <FlexBox
      borderColor={Color.primary}
      borderRadius={BorderRadius.small}
      borderStyle="solid"
      borderWidth={1}
      flexDirection="row"
      hSpacing={Spacing.mediumLarge}
      spacing={Spacing.smallMedium}
      width="fit-content"
      onClick={onPress}
      style={{ cursor: 'pointer' }}
    >
      <MuiTypography color={Color.primary} style={{ marginRight: Spacing.smallMedium }}>
        {attachment.title}
      </MuiTypography>
      <Svg
        set={loading ? 'downloading' : 'material'}
        name="get-app"
        color={Color.primary}
        size={IconSize.base}
      />
    </FlexBox>
  );
};

const MessageDetailsContent = (props: { message: MessageDetail }) => (
  <MuiBox>
    <Spacer spacing="large" />
    <MuiBox
      style={{
        wordWrap: 'break-word'
      }}
    >
      <MuiTypography>{getMessageText(props.message, true)}</MuiTypography>
    </MuiBox>

    <Spacer spacing="large" />
  </MuiBox>
);

export interface MessageDetailsScreenParams {
  folder: 'inbox' | 'outbox';
  source?: 'cdr' | 'cerner';
  mhpDetails: string;
}

interface MessageDetailsScreenProps extends NavigationScreenProps {
  resetMessageDetail: () => void;
  isFetching: boolean;
  error: null | Error;
  data: null | MessageDetail;
  getMessageById: typeof getMessageById;
  onReply: typeof messageReply;
  handleDirectReply: typeof startDirectReply;
  onDownloadAttachment: (attachment: MessageAttachment) => void;
  downloads: MessageDownloadState;
  isSending: boolean;
  currentRouteName?: string;
  previousRouteName?: string;
  messageDetailsDowloading: boolean;
  downloadMessageDetails: typeof downloadMessageDetails;
}

const CenteredScreenContent = ({
  children,
  ...rest
}: {
  children: React.ReactNode;
  testID?: string;
}) => (
  <MuiBox display="flex" p={3} alignItems="center" justifyContent="center" {...rest}>
    {children}
  </MuiBox>
);

const MessageDetailsNoData = () => (
  <CenteredScreenContent testID="message-empty">
    <MuiTypography>Message was not found</MuiTypography>
  </CenteredScreenContent>
);

const MessageDetailsLoading = () => (
  <CenteredScreenContent testID="message-loading">
    <Spinner />
  </CenteredScreenContent>
);

const MessageDetailsError = () => (
  <CenteredScreenContent testID="message-error">
    <MuiTypography>There was an issue fetching message details</MuiTypography>
  </CenteredScreenContent>
);

const getMessageIsDownloading = (
  downloads: MessageDownloadState,
  attachment: MessageAttachment
) => {
  if (
    downloads[attachment.mediaIdentifier] &&
    downloads[attachment.mediaIdentifier].isDownloading
  ) {
    return true;
  }

  return false;
};

export const MessageDetailsScreen = (props: MessageDetailsScreenProps) => {
  const { params } = props.match;
  const { folder: messageFolder, source, mhpDetails } = params as MessageDetailsScreenParams;
  const [open, setOpen] = useState(true);
  const history = useHistory();

  const onClose = () => {
    setOpen(false);
    history.goBack();
  };

  const handleReply = () => {
    if (!props.data) {
      return;
    }

    props.handleDirectReply(
      createMessageComposeParamsFromMessageDetails(props.data),
      props.data.messagePool,
      props.data.senderPoolName
    );
  };

  const renderAttachmentsList = ({ attachments }: { attachments?: MessageAttachment[] }) => {
    return attachments && attachments.length ? (
      <FlexBox spacing={Spacing.medium}>
        {attachments.map(item => (
          <>
            <MessageDetailsContentAttachment
              key={item.mediaIdentifier}
              loading={getMessageIsDownloading(props.downloads, item)}
              attachment={item}
              onPress={() => props.onDownloadAttachment(item)}
            />
            <Spacer spacing="medium" />
          </>
        ))}
      </FlexBox>
    ) : null;
  };

  useEffect(() => {
    props.getMessageById(objectFromQsParam(mhpDetails));
    return () => {
      props.resetMessageDetail();
    };
  }, [mhpDetails]);

  useEffect(() => {
    if (messageFolder === MessageFolderType.Inbox && props?.data) {
      const message = getMessageText(props.data, false);
      const data = {
        currentUrl: props?.currentRouteName,
        referringUrl: props?.previousRouteName,
        message_pool_type: props?.data?.messagePool?.poolTypeName || '',
        message_pool: props?.data?.senderDisplayName,
        message_provider_id: props?.data?.senderCernerPersonnelId,
        messageUUID: props?.data?.id,
        char_count: inboundMessageCounter(message, 'chars'),
        word_count: inboundMessageCounter(message, 'words')
      };
      analyticsService.logEvent(AnalyticsEvent.MessageRead, data);
    }
  }, [props.data]);

  const handleDownloadMessagePress = () => {
    if (props.data) {
      analyticsService.logEvent(AnalyticsEvent.DownloadMessageDetailsClicked, {
        messageUUID: props.data.id
      });
      props.downloadMessageDetails(objectFromQsParam(mhpDetails), props.data.id);
    }
  };

  const renderCTARow = () => (
    <MuiBox>
      <CallToActionRow
        isPatientToolbar
        isDownloading={props.messageDetailsDowloading}
        justifyContent="flex-end"
        onDownloadPress={handleDownloadMessagePress}
      />
    </MuiBox>
  );

  return (
    <Screen>
      <SpinnerOverlay isLoading={props.isSending} />
      <MuiBox>
        <MuiBox
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          height="40px"
          padding="10px 20px"
          color={Color.white}
          style={{
            background: Color.primary
          }}
        >
          <MuiTypography fontWeight={FontWeight.bold} fontSize={FontSize.large}>
            Message Details
          </MuiTypography>
          <Icon
            name="close"
            color={Color.white}
            size={IconSize.base}
            onClick={onClose}
            accessibilityLabel="Close compose message"
          />
        </MuiBox>
        {renderCTARow()}
        <Spacer spacing="xLarge" />
      </MuiBox>
      <MuiContainer maxWidth="lg">
        <MuiBox display="flex" justifyContent="center" px={2}>
          <DataLoader
            data={props.data}
            error={props.error}
            loading={props.isFetching}
            renderError={() => <MessageDetailsError />}
            renderNoData={() => <MessageDetailsNoData />}
            renderLoading={() => <MessageDetailsLoading />}
            renderData={data => {
              if (data === null) return <div />;

              return (
                <MuiBox flex="1 1 auto" width="100%">
                  <MuiBox flex="1 1 auto">
                    <MessageDetailsHeader onClose={onClose} message={data} />
                    <MessageDetailsContent message={data} />
                    {renderAttachmentsList({ attachments: data.attachments })}
                  </MuiBox>
                  {source !== 'cdr' &&
                  messageFolder !== 'outbox' &&
                  data.allowReply &&
                  data.messagePool?.groupDisplayName &&
                  data.messageType?.toUpperCase() !== 'REMINDER' ? (
                    <MuiBox
                      display="flex"
                      justifyContent="flex-end"
                      width="100%"
                      letterSpacing="0.25px"
                    >
                      <MuiButton
                        style={{ height: '45px', width: '284px' }}
                        variant="contained"
                        color="primary"
                        onClick={handleReply}
                      >
                        <MuiTypography fontWeight={400}>Reply</MuiTypography>
                      </MuiButton>
                    </MuiBox>
                  ) : null}
                </MuiBox>
              );
            }}
          />
        </MuiBox>
      </MuiContainer>
    </Screen>
  );
};

const mapState = (state: RootState) => ({
  isFetching: messageDetailFetchingSelector(state),
  error: messageDetailErrorSelector(state),
  data: messageDetailDataSelector(state),
  downloads: messageDownloadsSelector(state),
  currentRouteName: currentLocationPathNameSelector(state),
  previousRouteName: previousLocationPathNameSelector(state),
  messageDetailsDowloading: messageDetailsDownloadingSelector(state),
  messageDetailsDowloadData: messageDetailsDownloadsSelector(state)
});

const mapDispatch = {
  resetMessageDetail,
  onReply: messageReply,
  onDownloadAttachment: downloadMessageAttachment,
  getMessageById,
  handleDirectReply: startDirectReply,
  downloadMessageDetails
};

export default connect(mapState, mapDispatch)(MessageDetailsScreen as ComponentType);
