import useMediaQuery from '@material-ui/core/useMediaQuery';
import HealthToolsCards from 'components/HealthToolsCards';
import FlexBox, { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import Svg from 'components/UI/Svg/Svg';
import { HEALTH_TOOLS_COMPLETED } from 'lib/healthTools/constants';
import { history } from 'lib/history';
import { Color } from 'modules/styles/colors';
import { FontSize, IconSize, Spacing } from 'modules/styles/variables';
import React, { ComponentType, useState, useEffect } from 'react';
import analyticsService, {
  AMPLITUDE_EXPERIMENTS,
  MentalHealthType
} from 'services/AnalyticsService';
import { connect } from 'react-redux';
import { isHealthToolSurveyCompletedSelector } from 'store/consumerPreferences/selectors';
import { RootState } from 'store/types';
import { dfdDefaultTheme, MuiBox, MuiButton, MuiContainer, MuiTypography } from 'theme/material-ui';
import { getHealthToolsData } from './utils';
import { isFeatureFlagOnSelector } from 'shared/src/store/appSettings/selectors';

interface Props {
  isHealthToolSurveyCompleted: boolean;
  myStrengthEnabled?: boolean;
  prediabetesEnabled?: boolean;
  vyncaHealthToolEnabled?: boolean;
}

const HealthTools = (props: Props) => {
  const {
    isHealthToolSurveyCompleted,
    myStrengthEnabled,
    prediabetesEnabled,
    vyncaHealthToolEnabled
  } = props;
  const [showSurveyCompletedDailog, setShowSurveyCompletedDailog] = useState(false);
  const [showStandardDesign, setShowStandardDesign] = useState(true);
  const [mentalHealthVariant, setMentalHealthVariant] = useState<MentalHealthType>(
    AMPLITUDE_EXPERIMENTS.HEALTH_TOOLS_MENTAL_HEALTH.variantA as MentalHealthType
  );
  useEffect(() => {
    const result = analyticsService.fetchExperimentVariant(
      AMPLITUDE_EXPERIMENTS?.VYNCA_SCREEN?.flagKey
    );
    setShowStandardDesign(result?.value === AMPLITUDE_EXPERIMENTS.VYNCA_SCREEN.variantA);

    const mentalObj = analyticsService.fetchExperimentVariant(
      AMPLITUDE_EXPERIMENTS.HEALTH_TOOLS_MENTAL_HEALTH.flagKey
    );
    setMentalHealthVariant((mentalObj?.value || 'a') as MentalHealthType);
  }, []);
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('md'));

  const navigateToSurveyShareIdeas = () => {
    if (isHealthToolSurveyCompleted) {
      setShowSurveyCompletedDailog(true);
    }
    return isHealthToolSurveyCompleted;
  };

  const navigateToSurvey = () => {
    if (!navigateToSurveyShareIdeas()) history.push('/u/health-tools/survey');
  };

  const navigateToDashBoard = () => history.push('/u/dashboard');

  return (
    <>
      {/* Todo: refactor this modal into a component in external file. */}
      <ConfirmModal
        accessibilityLabel="confirm modal"
        isOpen={showSurveyCompletedDailog}
        onConfirm={() => {
          setShowSurveyCompletedDailog(false);
          navigateToDashBoard();
        }}
        confirmLabel="Close"
        maxWidth={isSmallScreen ? '100%' : '50%'}
        subTitleElement={() => {
          return (
            <>
              <FlexBox vSpacing={Spacing.xLarge} hSpacing={Spacing.large}>
                <FlexBox
                  justifyContent="center"
                  alignItems="center"
                  vSpacing={Spacing.large}
                  hSpacing={Spacing.large}
                >
                  <Svg name="IllustrationsHealthRecord" height="100" width="100" />
                </FlexBox>
                <Spacer spacing="large" />
                <MuiTypography variant="h6">{HEALTH_TOOLS_COMPLETED}</MuiTypography>
              </FlexBox>
            </>
          );
        }}
      />

      <MuiBox my={5}>
        <MuiContainer maxWidth="xl">
          <FlexBox alignItems="center">
            {vyncaHealthToolEnabled ? (
              <HealthToolsCards
                toolsData={getHealthToolsData(showStandardDesign, mentalHealthVariant, {
                  myStrengthEnabled,
                  prediabetesEnabled
                })}
                navigateToSurveyShareIdeas={navigateToSurveyShareIdeas}
                isHealthToolSurveyCompleted={isHealthToolSurveyCompleted}
              />
            ) : (
              <>
                <FlexBox hSpacingLeft={Spacing.xLarge}>
                  <Svg
                    set="assets"
                    fill={Color.grayHue6}
                    name="DocPhone"
                    size={3 * IconSize.xLarge}
                  />
                </FlexBox>
                <FlexBoxRow justifyContent="center">
                  <MuiTypography>
                    <MuiTypography fontSize={FontSize.heading}>
                      Our goal is to help you live the healthiest life <br />
                      possible by offering great digital tools and <br />
                      resources.
                    </MuiTypography>
                    <Spacer size="medium" />
                    <MuiTypography fontSize={FontSize.heading}>
                      As we plan out these new digital tools, we
                      <br />
                      would like you to take a few minutes and <br />
                      share your ideas and feedback.
                    </MuiTypography>
                  </MuiTypography>
                </FlexBoxRow>
              </>
            )}

            <Spacer size="medium" />

            {!props.isHealthToolSurveyCompleted && (
              <FlexBoxRow justifyContent="center">
                {!vyncaHealthToolEnabled ? (
                  <MuiButton
                    data-testid="share-your-ideas-feedback"
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={navigateToSurvey}
                  >
                    <MuiTypography fontSize={FontSize.large}>
                      Share your ideas/feedback
                    </MuiTypography>
                  </MuiButton>
                ) : null}
              </FlexBoxRow>
            )}
          </FlexBox>
        </MuiContainer>
      </MuiBox>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isHealthToolSurveyCompleted: isHealthToolSurveyCompletedSelector(state),
  myStrengthEnabled: isFeatureFlagOnSelector(state)('MYSTRENGTH_HEALTH_TOOL_ENABLED'),
  prediabetesEnabled: isFeatureFlagOnSelector(state)('PREDIABETES_HEALTH_TOOL_ENABLED'),
  vyncaHealthToolEnabled: isFeatureFlagOnSelector(state)('VYNCA_HEALTH_TOOL')
});

export default connect(mapStateToProps)(HealthTools) as ComponentType;
