import { HealthToolCardData } from 'components/HealthToolsCards/HealthToolCard';
import VyncaBanner from '../../assets/HealthTools/vyncaB-thumb.png';
import HealthToolsSurveyScreen from './Survey/HealthToolsSurveyScreen';
import { vyncaCard, vyncaRouteData } from './Cards/vynca';
import { myStrengthCard } from './Cards/myStrength';
import { diabetesCard, preventDiabetesRouteData } from './Cards/preventDiabetes';
import { RouteData } from 'screens/navigation';
import { MentalHealthType } from 'services/AnalyticsService.common';

export const getHealthToolsData = (
  variant: boolean,
  mentalHealthVariant: MentalHealthType,
  FF: { myStrengthEnabled?: boolean; prediabetesEnabled?: boolean }
) => {
  const vyncaVariantBData: HealthToolCardData = {
    healthToolCardKey: 'vyncaB',
    description: 'Take Control with Advance Care Planning',
    path: '/u/health-tools/vynca',
    thumb: VyncaBanner
  };
  const toolsData: HealthToolCardData[] = [];

  if (FF.myStrengthEnabled) {
    toolsData.push(myStrengthCard(mentalHealthVariant));
  }
  if (!variant) {
    toolsData.push(vyncaVariantBData);
  } else {
    toolsData.push(vyncaCard);
  }

  if (FF.prediabetesEnabled) {
    toolsData.push(diabetesCard);
  }

  return toolsData;
};

const healthToolsSurveyRouteData: RouteData = {
  path: '/u/health-tools/survey',
  component: HealthToolsSurveyScreen,
  componentName: 'HealthToolsSurveyScreen',
  title: 'Health Tools Survey',
  crumbLabel: 'Health Tools Survey',
  exact: true,
  includeDefaultLayout: true,
  includeBanner: true,
  includeCrumbs: true,
  icon: 'HealthTool'
};

export const getScreensOfHealthTools = (prediabetesEnabled?: boolean): RouteData[] => {
  const screens: RouteData[] = [];
  screens.push(healthToolsSurveyRouteData);

  screens.push(...vyncaRouteData);

  if (prediabetesEnabled) {
    screens.push(...preventDiabetesRouteData);
  }

  return screens;
};
