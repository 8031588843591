import React from 'react';
import { History } from 'history';
import startCase from 'lodash/startCase';
import Config from 'react-native-config';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';
import esLocale from 'dayjs/locale/es';
import DayJSUtils from '@date-io/dayjs';
import enLocale from 'dayjs/locale/en';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { HEALTH_RECORDS_PAGES } from 'lib/constants/healthRecords';
import { IconSize } from 'modules/styles/variables';
import { setIsExternalRequest } from 'store/booking/appointment/appointmentDetails/actions';
import { setDoctor, setPatient } from 'store/booking/actions';
import { getCurrentSignedInUserPrimaryDoctor } from 'store/myDoctors/actions';
import { currentNavigationWebStateSelector } from 'store/navigation/selectors';
import { activityStatusSelector } from 'store/amwell/selectors';
import { NavStateEnum } from 'store/navigation/types';
import { RootState } from 'store/types';
import { logout } from 'store/authentication/actions';
import { isDfdAuthenticatedSelector } from 'store/authentication/selectors';
import { hasFetchedDependencies } from 'store/global/selectors';
import { allActiveAccountsSelector } from 'store/account/selectors';

import { ReactInAppUserActivity } from 'components/ReactUserActivity';
import Banner from 'components/UI/Banner/Banner';
import { Breadcrumbs } from 'components/Breadcrumbs';
import VitalsBanner from 'components/vitals/VitalsBanner/VitalsBanner';
import ConnectCareActivity from 'components/ConnectCare';
import { Snacks } from 'components/Snack';
import AuthLayout from 'components/AuthLayout';
import { useRoutesToCrumbs } from 'hooks/useRoutesToCrumbs';
import LabHistoricalScreen from 'screens/TestResults/Lab/LabHistoricalScreen';
import MicrobiologyHistoricalScreen from 'screens/TestResults/Microbiology/MicrobiologyHistoricalScreen';
import TriageGyantRoutes from 'screens/TriageGyant/navigation';
import EVisitsRoutes from 'screens/EVisits/navigation';
import ClinicNotes from 'screens/ClinicNotes';
import ClinicNotesViewAll from 'screens/ClinicNotes/ClinicNotesViewAll';

import AppBanner from './AppBanner';
import AuthCallback from './AuthCallback/AuthCallback';
import AccountSelect from './AccountSelect';
import { BillingDetail } from './Billing/BillingDetail';
import { BillingHome } from './Billing/BillingHome';
import { BillingSummary } from 'screens/Billing/BillingSummary';
import HSADetailsScreen from './Billing/HSA/HSADetailsScreen';
import PaymentConfirmation from './Billing/Payment/PaymentConfirmation';
import PaymentScreen from './Billing/Payment/PaymentScreen';
import PinAuthenticationScreen from './Billing/Payment/PinAuthenticationScreen';
import PaymentHistory from './Billing/PaymentHistory';
import PaymentDetail from './Billing/PaymentDetail';
import { BillSummary } from './Billing/BillSummary';
import CollectionsSummary from './Billing/CollectionsSummary';
import BookingRouter from './Booking/router';
import FARouter from './Billing/FinancialAssistance/router';
import RTADetail from './Billing/RTA/RTADetail';
import ConnectCareRouter from './ConnectCare/router';
import CovidTestRouter from './CovidScreening/router';
import { covidTestGuestScreens } from './CovidTestGuest/navigation/router';
import { CostEstimatorRouter } from './Cost/router';
import Dashboard from './Dashboard/Dashboard';
import AntiCoagulationAlertScreen from './Dashboard/DashboardCarousel/AntiCoagulationAlert';
import CloseHealthAdvisoryScreen from './EmbeddedMessaging/CloseHealthAdvisoryScreen';
import SearchResults from './GetCare/LiveCare/SearchResults';
import EmotionalSupport from './GetCare/LiveCare/EmotionalSupport';
import GetCareHomeScreen from './GetCareHome/GetCareHome';
import GetCareSearch from './GetCareHome/GetCareSearch';
import Glossary from './Glossary/Glossary';
import GuestIntroductionRoutes from './Guest/navigation';
import { getScreensOfHealthTools } from './HealthTools/utils';
import HealthRecordsHome from './HealthRecords/HealthRecordsHome';
import EventLogin from './HealthRecords/EventLogin';
import EventLinkExpired from './HealthRecords/EventLogin/EventLinkExpired';
import EventTestResult from './HealthRecords/EventTestResult';
import AuthLitePatientLetter from './HealthRecords/EventTestResult/AuthLitePatientLetter';
import { GoodFaithEstimateAuthLiteLogin } from './HealthRecords/GoodFaithEstimate/AuthLiteLogin';
import GoodFaithEstimateDocumentView from './HealthRecords/GoodFaithEstimate/AuthLiteDocumentView';
import AboutScreen from './Help/AboutScreen';
import ContactUsScreen from './Help/ContactUsScreen';
import { HelpScreen } from './Help/HelpScreen';
import Landing from './Landing/Landing';
import Loading from './Loading/Loading';
import Allergies from './MedicalHistory/Allergies/Allergies';
import Immunizations from './MedicalHistory/Immunizations/Immunizations';
import UtahSchoolImmunizationRecord from './MedicalHistory/Immunizations/UtahSchoolImmunizationRecord';
import ProblemList from './MedicalHistory/ProblemList/ProblemList';
import Procedures from './MedicalHistory/Procedures/Procedures';
import SocialHistory from './MedicalHistory/SocialHistory/SocialHistory';
import PatientEducationDetail from './PatientEducation/PatientEducationDetail';
import { PatientEducationViewAll } from './PatientEducation/PatientEducationViewAll';
import PastMedications from './Medications/PastMedications';
import CurrentMedications from './Medications/CurrentMedications';
import CurrentMedicationDetailsSummary from './Medications/CurrentMedicationDetailsSummary';
import PastMedicationDetailsSummary from './Medications/PastMedicationDetailsSummary';
import RenewPrescriptionConfirmation from './Medications/RenewPrescriptionConfirmation';
import MessageDetailsScreen from './Messaging/MessageDetailsScreen';
import MessageHomeScreen from './Messaging/MessageHomeScreen';
import MessagingPoolSelectScreen from './Messaging/MessagingPoolSelectScreen';
import EventsResolver from './NavigationState/EventsResolver';
import NavState from './NavigationState/NavState';
import { ProfileRouter } from './Profile/router';
import ProviderSearch from './ProviderSearch';
import ProviderDetailsScreen from './ProviderDetails/ProviderDetails';
import proxyFormRoutes from './ProxyForm/navigation/router';
import Register from './Register';
import ShareFeedbackScreen from './ShareFeedback/ShareFeedbackScreen';
import PatientLetter from './TestResults/Lab/PatientLetter';
import TestResults from './TestResults/TestResults';
import TestResultsHealthRecordsList from './TestResults/TestResultsList/TestResultsHealthRecordsList';
import TransmitLandingScreen from './Transmit/TransmitLandingScreen';
import TechnicalSupportScreen from './TechnicalSupport/TechnicalSupportScreen';
import Updates from './Updates/Updates';
import VisitSummaryFilter from './VisitSummary/VisitSummaryFilter/VisitSummaryFilter';
import VisitSummaryLive from './VisitSummary/VisitSummaryLive';
import VisitSummarySearchScreen from './VisitSummary/VisitSummarySearchScreen';
import VitalsTable from './Vitals/VitalsTable';
import ConfirmDialog from '../components/ConfirmDialog/ConfirmDialog';
import CustomModal from '../components/CustomModal';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { crumbLabels as translations } from 'lib/constants/translations/components/index';
import { languageSelector } from 'store/consumerPreferences/selectors';
import HealthTools from './HealthTools/HealthTools';
import KyruusProviderSearch from './ProviderSearch/KyruusProviderSearch';
import KyruusProviderDetails from './ProviderSearch/KyruusProviderDetails';
import BillingHelpCenter from './Billing/BillingHelpCenter';
import { isFeatureFlagOnSelector } from 'store/appSettings/selectors';

interface Props {
  align?: 'center' | 'flex-start';
  bannerComponent?: any;
  componentName?: string;
  display?: 'block' | 'flex';
  exact?: boolean;
  icon?: string | null;
  includeBanner?: boolean;
  public?: boolean;
  sidenav?: boolean;
  sidenavIcon?: string | null;
  sideNavOrder?: number;
  title?: string | JSX.Element;
  subMessage?: string;
  extraBannerPadding?: boolean;
  includeCrumbs?: boolean;
  crumbLabel?: string;
  crumbComponent?: any;
  hideDefaultLayout?: boolean; // prop used for AccountSelect screen
  includeDefaultLayout?: boolean; // prop used for layout consistency in unauth guest flows
}

export interface NavRoute {
  componentName: string;
  path: string;
  sidenavIcon: string;
  sidenavIconSize: number;
  sideNavOrder: number;
  title: string;
}

export interface RouteData extends Props {
  component: any;
  path: string;
}

export interface NavigationScreenProps extends RouteComponentProps {
  router?: History;
}

export interface Match<P = any> {
  params: P;
  isExact: boolean;
  path: string;
  url: string;
}

const bookingRoutes: RouteData[] = [
  {
    path: '/u/get-care-now/booking',
    component: BookingRouter,
    includeBanner: false,
    includeCrumbs: false
  }
];

const covidTestScreenRoutes: RouteData[] = [
  {
    path: '/u/get-care-now/covid-screen',
    component: CovidTestRouter,
    includeBanner: false,
    includeCrumbs: false
  }
];

export const DASHBOARD = 'Dashboard';

export const basePrivateRoutes: RouteData[] = [
  {
    exact: true,
    path: '/u/account-select',
    component: AccountSelect,
    componentName: 'AccountSelect',
    includeCrumbs: false,
    hideDefaultLayout: true
  },
  {
    public: true,
    exact: true,
    path: '/u/dashboard',
    component: Dashboard,
    componentName: DASHBOARD,
    sidenav: true,
    sidenavIcon: 'SNavDashboard',
    sideNavOrder: 1,
    title: DASHBOARD,
    includeCrumbs: false,
    includeBanner: false
  },
  {
    path: '/u/dashboard/anti-coagulation/:id',
    component: AntiCoagulationAlertScreen,
    componentName: 'AntiCoagulationAlert'
  },
  {
    path: '/events/:eventId',
    component: EventsResolver,
    componentName: 'EventsResolver'
  }
];
const messageRoutes: RouteData[] = [
  {
    component: MessageHomeScreen,
    componentName: 'MessageHomeScreen',
    display: 'block',
    exact: true,
    icon: 'Messages',
    includeBanner: false,
    path: '/u/messages',
    sidenav: true,
    includeCrumbs: false,
    sidenavIcon: 'SNavMessages',
    sideNavOrder: 6,
    title: 'Messages'
  },
  {
    exact: true,
    path: '/u/messages/pool',
    component: MessagingPoolSelectScreen,
    componentName: 'MessagingPoolSelectScreen',
    title: 'Select Provider Location'
  },
  {
    exact: true,
    path: '/u/messages/transmit',
    includeBanner: false,
    component: TransmitLandingScreen,
    componentName: 'TransmitLandingScreen'
  },
  {
    exact: true,
    path: '/u/messages/:folder/:source/:mhpDetails',
    component: MessageDetailsScreen,
    componentName: 'MessageDetailsScreen',
    includeCrumbs: false,
    includeBanner: false
  }
];

const billingRoutes: RouteData[] = [
  {
    path: '/u/billing/bill-detail/:eaid',
    component: BillingDetail,
    componentName: 'BillingDetail',
    title: 'Bill Detail'
  },
  {
    path: '/u/billing/pending-bill/:id',
    component: RTADetail,
    componentName: 'RTADetail',
    title: 'Upcoming Bill'
  },
  {
    bannerComponent: BillingSummary,
    component: BillingHome,
    componentName: 'BillingHomeScreen',
    exact: true,
    path: '/u/billing',
    sidenav: true,
    sidenavIcon: 'SNavBilling',
    sideNavOrder: 5,
    title: 'Billing'
  },
  {
    path: '/u/billing/payment-history',
    component: PaymentHistory,
    componentName: 'PaymentHistory',
    title: 'Payment History'
  },
  {
    path: '/u/billing/payment-detail/:receiptId',
    component: PaymentDetail,
    componentName: 'PaymentDetail',
    title: 'Payment Detail'
  },
  {
    path: '/u/billing/billing-payment-plans',
    component: BillingHome,
    componentName: 'BillingHome',
    title: 'Payment Plans'
  },
  {
    path: '/u/billing/billpay',
    component: PaymentScreen,
    componentName: 'PaymentScreen',
    title: 'Make A Payment'
  },
  {
    path: '/u/billing/billpay-guest',
    component: PinAuthenticationScreen,
    componentName: 'PinAuthenticationScreen',
    title: 'Billing'
  },
  {
    path: '/u/billing/billpay-receipt',
    component: PaymentConfirmation,
    componentName: 'PaymentConfirmation',
    title: 'Payment Receipt'
  },
  {
    path: '/u/billing/glossary',
    component: Glossary,
    componentName: 'Glossary',
    title: `Glossary of Billing Terms`
  },
  {
    path: '/u/billing/bills',
    component: BillSummary,
    componentName: 'BillSummary',
    title: 'All Bills'
  },
  {
    path: '/u/billing/collections',
    component: CollectionsSummary,
    componentName: 'CollectionsSummary',
    title: 'Collections'
  },
  {
    path: '/u/billing/billing-help',
    component: BillingHelpCenter,
    componentName: 'BillingHelpCenter',
    title: 'Billing Help Center'
  }
];

const healthToolsRoute: RouteData[] = [
  {
    component: HealthTools,
    componentName: 'HealthTools',
    crumbLabel: 'Health Tools',
    display: 'block',
    exact: true,
    icon: 'HealthTool',
    includeBanner: true,
    includeCrumbs: true,
    path: '/u/health-tools',
    sidenav: true,
    sidenavIcon: 'SNavHealthTools',
    sideNavOrder: 7,
    subMessage: 'Resources to help you live a healthy life',
    title: 'Health Tools'
  }
];

const estimateCostsRoutes: RouteData[] = [
  {
    path: '/u/estimate-costs',
    component: CostEstimatorRouter,
    componentName: 'CostEstimator',
    title: 'Find the Care You Need',
    includeCrumbs: false
  }
];

const financialAssistanceRoutes: RouteData[] = [
  {
    path: '/u/fa-app',
    component: FARouter,
    componentName: 'FAInitiation',
    includeBanner: false,
    includeCrumbs: false
  },
  {
    path: '/u/fa-app/eligibility',
    component: FARouter,
    componentName: 'FAEligibility',
    includeBanner: false,
    includeCrumbs: false
  }
];

const findADoctorRoutes: RouteData[] = [
  {
    path: '/u/find-a-doctor',
    includeBanner: false,
    component: ProviderSearch,
    componentName: 'ProviderSearch',
    title: 'Find a Doctor or Specialist',
    exact: true,
    display: 'block',
    align: 'center'
  },
  {
    path: '/u/provider/kyruus/details',
    component: KyruusProviderDetails,
    componentName: 'KyruusProviderDetailsScreen',
    includeBanner: false
  },
  {
    path: '/u/provider/:id',
    component: ProviderDetailsScreen,
    componentName: 'ProviderDetailsScreen',
    includeBanner: false
  },
  {
    path: '/u/find-a-doctor/providers-at-location',
    component: ProviderSearch,
    componentName: 'ProviderSearch',
    title: 'Providers at Location'
  },
  {
    path: '/u/find-a-doctor/results',
    component: ProviderSearch,
    componentName: 'ProviderSearchResult',
    title: 'Search Results'
  }
];

const getCareRoutes: RouteData[] = [
  {
    exact: true,
    path: '/u/get-care-now',
    component: GetCareHomeScreen,
    bannerComponent: GetCareSearch,
    componentName: 'GetCareHomeScreen',
    title: 'Find a Doctor or Specialist',
    crumbLabel: 'Get Care',
    align: 'center'
  },
  {
    exact: true,
    icon: 'ConnectCare',
    component: GetCareHomeScreen,
    componentName: 'GetCareHomeScreen',
    path: '/u/get-care-now',
    sidenav: true,
    sidenavIcon: 'SNavGetCare',
    sideNavOrder: 4,
    title: 'Get Care'
  },
  {
    path: '/u/get-care-now/connect-care',
    component: ConnectCareRouter,
    includeBanner: false,
    includeCrumbs: false
  },
  {
    path: '/u/get-care-now/live-care/:facilityType',
    component: SearchResults,
    componentName: 'SearchResults',
    bannerComponent: false
  },
  {
    path: '/u/get-care-now/care/:emotionalSupport',
    component: EmotionalSupport,
    componentName: 'EmotionalSupport',
    includeBanner: false
  }
];

const healthRecordsRoutes: RouteData[] = [
  {
    bannerComponent: VitalsBanner,
    component: HealthRecordsHome,
    componentName: 'HealthRecordsHome',
    exact: true,
    path: '/u/health-record',
    sidenav: true,
    sidenavIcon: 'SNavHealthRecord',
    sideNavOrder: 3,
    title: 'Health Record',
    icon: 'CAlphaHealthRecord'
  },
  {
    path: '/u/health-record/a1c',
    component: HealthRecordsHome,
    componentName: 'HealthRecordsHome',
    title: 'A1C Panel'
  },
  {
    path: '/u/health-record/all-documents',
    component: HealthRecordsHome,
    componentName: 'HealthRecordsHome',
    title: 'My Documents'
  },
  {
    path: '/u/health-record/renew-prescription',
    component: RenewPrescriptionConfirmation,
    componentName: 'RenewPrescriptions'
  },
  {
    path: '/u/health-record/past-medications',
    component: PastMedications,
    componentName: 'PastMedications',
    title: 'Past Medications',
    exact: true
  },
  {
    path: '/u/health-record/current-medications',
    component: CurrentMedications,
    exact: true,
    componentName: 'CurrentMedications',
    title: 'Current Medications'
  },
  {
    path: '/u/health-record/current-medications/medications/:id/:mhpDetails?',
    component: CurrentMedicationDetailsSummary,
    componentName: 'Medications',
    title: 'Medication Details',
    includeBanner: false,
    includeCrumbs: true
  },
  {
    path: '/u/health-record/medications/:id/:mhpDetails?',
    component: CurrentMedicationDetailsSummary,
    componentName: 'Medications',
    title: 'Medication Details',
    includeBanner: false,
    includeCrumbs: true
  },
  {
    path: '/u/health-record/past-medications/medications/:id/:mhpDetails?',
    component: PastMedicationDetailsSummary,
    componentName: 'Medications',
    title: 'Medication Details',
    includeBanner: false,
    includeCrumbs: true
  },
  {
    path: '/u/health-record/all-notifications',
    component: Updates,
    componentName: 'Updates',
    title: 'Updates'
  },
  {
    path: '/u/health-record/test-results/:id/:mhpDetails?',
    component: TestResults,
    componentName: 'TestResults',
    includeBanner: false
  },
  {
    path: '/u/health-record/patient-letters/:docId',
    component: PatientLetter,
    componentName: 'PatientLetter'
  },
  {
    path: '/u/health-record/lab-history',
    component: LabHistoricalScreen,
    componentName: 'LabHistoricalScreen',
    includeBanner: false
  },
  {
    path: '/u/health-record/microbiology-history',
    component: MicrobiologyHistoricalScreen,
    componentName: 'MicrobiologyHistoricalScreen',
    includeBanner: false
  },
  {
    path: '/u/health-record/visit-summaries/live/:source/:encounterId/:dateOfEncounter?',
    component: VisitSummaryLive,
    componentName: 'VisitSummaryLive'
  },
  {
    path: '/u/health-record/visit-summaries/live-detailed/:source/:encounterId/:mhpDetails?',
    component: VisitSummaryLive,
    componentName: 'VisitSummaryLive'
  },
  {
    path: '/u/health-record/test-results',
    component: TestResultsHealthRecordsList,
    componentName: 'TestResultsHealthRecordsList',
    title: 'Test Results'
  },
  {
    path: '/u/health-record/visit-summaries/filter',
    component: VisitSummaryFilter,
    componentName: 'VisitSummaryFilter',
    title: 'Visit Summaries Filter'
  },
  {
    path: '/u/health-record/visit-summaries',
    component: VisitSummarySearchScreen,
    componentName: 'VisitSummarySearchScreen',
    title: 'Visit Summaries'
  },
  {
    path: '/u/health-record/allergies',
    component: Allergies,
    componentName: 'Allergies',
    title: 'Allergies'
  },
  {
    path: '/u/health-record/document',
    component: HealthRecordsHome,
    componentName: 'HealthRecordsHome',
    title: 'Document Detail'
  },
  {
    path: '/u/health-record/family-history',
    component: HealthRecordsHome,
    componentName: 'HealthRecordsHome',
    title: 'Family Medical History'
  },
  {
    path: '/u/health-record/immunizations',
    component: Immunizations,
    componentName: 'Immunizations',
    title: 'Immunizations'
  },
  {
    path: '/u/health-record/usir',
    component: UtahSchoolImmunizationRecord,
    componentName: 'UtahSchoolImmunizationRecord',
    title: HEALTH_RECORDS_PAGES.MedicalHistory.usirButton
  },
  {
    path: '/u/health-record/lab-detail',
    component: HealthRecordsHome,
    componentName: 'HealthRecordsHome',
    title: 'Lab Detail'
  },
  {
    path: '/u/health-record/lipids',
    component: HealthRecordsHome,
    componentName: 'HealthRecordsHome',
    title: 'Lipid Panel'
  },
  {
    path: '/u/health-record/microbiology-historical',
    component: HealthRecordsHome,
    componentName: 'HealthRecordsHome',
    title: 'Microbiology History'
  },
  {
    path: '/u/health-record/past-procedures',
    component: Procedures,
    componentName: 'Procedures',
    title: 'Procedure History'
  },
  {
    path: '/u/health-record/patient-education/detail/:accessCode',
    component: PatientEducationDetail,
    componentName: 'PatientEducationDetail',
    title: 'Education Detail'
  },
  {
    path: '/u/health-record/patient-education',
    component: PatientEducationViewAll,
    componentName: 'PatientEducation',
    title: 'Education'
  },
  {
    path: '/u/health-record/clinic-notes-and-documents/:clinicNoteType',
    component: ClinicNotes,
    componentName: 'ClinicNotes',
    title: 'Document Detail',
    includeBanner: false
  },
  {
    path: '/u/health-record/clinic-notes-and-documents',
    component: ClinicNotesViewAll,
    componentName: 'ClinicNotesViewAll',
    title: 'Clinic Notes And Documents'
  },
  {
    path: '/u/health-record/preventative-care/:advisoryName/:eventId',
    component: CloseHealthAdvisoryScreen,
    componentName: 'CloseHealthAdvisoryScreen',
    title: 'Preventive Care Advisory'
  },
  {
    path: '/u/health-record/problems',
    component: ProblemList,
    componentName: 'ProblemList',
    title: 'Problem List'
  },
  {
    path: '/u/health-record/respiration',
    component: HealthRecordsHome,
    componentName: 'HealthRecordsHome',
    title: 'Respiration Rate'
  },
  {
    path: '/u/health-record/social-history',
    component: SocialHistory,
    componentName: 'SocialHistory',
    title: 'Social History'
  },
  {
    path: '/u/health-record/test-results-visit',
    component: VisitSummaryLive,
    componentName: 'VisitSummaryLive',
    title: 'Visit Summary'
  },
  {
    path: '/u/health-record/visit/:source/:id',
    component: VisitSummaryLive,
    componentName: 'VisitSummaryLive',
    includeBanner: false
  },
  {
    path: '/u/health-record/vitals/:name/:title',
    component: VitalsTable,
    componentName: 'VitalsTable',
    title: 'Vitals'
  },
  {
    path: '/u/hsaDetails',
    component: HSADetailsScreen,
    componentName: 'HSADetailsScreen'
  }
];

const manageAccountRoutes: RouteData[] = [
  {
    path: '/u/manage-account',
    component: ProfileRouter,
    includeBanner: false,
    includeCrumbs: false
  }
];

const loadingRoutes: RouteData[] = [
  {
    exact: true,
    path: '/auth/:service/callback',
    component: AuthCallback,
    componentName: 'AuthCallback'
  }
];

const helpRoutes: RouteData[] = [
  {
    path: '/u/help-support',
    component: HelpScreen,
    componentName: 'HelpScreen',
    exact: true,
    includeDefaultLayout: true,
    includeBanner: true,
    title: 'Get Help',
    includeCrumbs: false
  },
  {
    path: '/u/help-support/contact',
    component: ContactUsScreen,
    componentName: 'ContactUsScreen',
    exact: true,
    includeDefaultLayout: true,
    includeBanner: true,
    title: 'Contact Us',
    includeCrumbs: true
  },
  {
    path: '/u/help-support/technical-support',
    component: TechnicalSupportScreen,
    componentName: 'TechnicalSupportScreen',
    exact: true,
    includeDefaultLayout: true,
    includeBanner: true,
    title: 'Technical Support',
    includeCrumbs: true
  },
  {
    path: '/u/help-support/share-feedback',
    component: ShareFeedbackScreen,
    componentName: 'ShareFeedbackScreen',
    exact: true,
    includeDefaultLayout: true,
    includeBanner: true,
    title: 'Share Feedback',
    includeCrumbs: true
  },
  {
    path: '/u/help-support/about',
    component: AboutScreen,
    componentName: 'AboutScreen',
    exact: true,
    includeDefaultLayout: true,
    includeBanner: true,
    title: 'About This App',
    includeCrumbs: true
  }
];

export const privateRoutes: RouteData[] = [
  ...bookingRoutes,
  ...basePrivateRoutes,
  ...billingRoutes,
  ...healthToolsRoute,
  ...covidTestScreenRoutes,
  ...estimateCostsRoutes,
  ...findADoctorRoutes,
  ...getCareRoutes,
  ...healthRecordsRoutes,
  ...manageAccountRoutes,
  ...messageRoutes,
  ...helpRoutes,
  ...proxyFormRoutes,
  ...EVisitsRoutes
];

if (Config.FA_APP === 'enabled') {
  privateRoutes.push(...financialAssistanceRoutes);
}
export const publicRoutes: RouteData[] = [
  {
    exact: true,
    path: '/landing',
    component: Landing,
    componentName: 'Landing'
  },
  {
    exact: true,
    path: '/loading',
    component: Loading,
    componentName: 'Loading'
  },
  {
    path: '/register',
    component: Register,
    componentName: 'Register'
  },
  {
    exact: true,
    path: '/find-a-doctor',
    component: ProviderSearch,
    componentName: 'ProviderSearch'
  },
  {
    exact: true,
    path: '/provider/:id',
    component: ProviderDetailsScreen,
    componentName: 'ProviderDetailsScreen',
    includeBanner: false,
    includeCrumbs: false
  },
  {
    path: '/get-care-now/live-care/:facilityType',
    component: SearchResults,
    componentName: 'SearchResults',
    bannerComponent: false
  },
  {
    path: '/get-care-now/care/:emotionalSupport',
    component: EmotionalSupport,
    componentName: 'EmotionalSupport',
    includeBanner: false,
    includeCrumbs: false
  },
  {
    // Auth-Lite Login
    path: '/al/events/:eventId',
    component: EventLogin,
    componentName: 'EventLogin',
    exact: true
  },
  {
    // GFE Auth-Lite Login
    path: '/al/events/doc/:eventId',
    component: GoodFaithEstimateAuthLiteLogin,
    componentName: 'GoodFaithEstimateAuthLiteLogin',
    exact: true
  },
  {
    // GFE Auth-Lite Document View
    path: '/al/good-faith-estimate/:mhpDetails',
    component: GoodFaithEstimateDocumentView,
    componentName: 'GoodFaithEstimateDocumentView',
    exact: true
  },
  {
    // Auth-Lite link expired
    path: '/al/link-expired',
    component: EventLinkExpired,
    componentName: 'EventLinkExpired',
    exact: true
  },
  {
    // Note: auth-light path
    path: '/al/test-results/:id',
    component: EventTestResult,
    componentName: 'EventTestResult',
    exact: true,
    includeBanner: false,
    includeCrumbs: false
  },
  {
    path: '/al/test-results/patient-letters/:docId',
    component: AuthLitePatientLetter,
    componentName: 'AuthLitePatientLetter',
    exact: true,
    includeBanner: false,
    includeCrumbs: false
  },
  ...helpRoutes,
  ...findADoctorRoutes,
  ...TriageGyantRoutes,
  ...covidTestGuestScreens,
  ...GuestIntroductionRoutes
];

export const getPathByComponentName = (componentName: string) => {
  const route = privateRoutes.concat(publicRoutes).find(r => r.componentName === componentName);

  return route ? route.path : '/';
};

export function getNavBarRoutes(): NavRoute[] {
  const navRoutes = privateRoutes
    .concat(publicRoutes)
    .filter((route: RouteData) => route.sidenav)
    .map((route: RouteData, idx: number) => {
      return {
        componentName: route.componentName,
        path: route.path,
        sidenavIcon: route.sidenavIcon,
        sidenavIconSize: IconSize.base,
        sideNavOrder: route.sideNavOrder || idx + 1,
        title: route.title
      } as NavRoute;
    });
  navRoutes.sort((a, b) => (a.sideNavOrder > b.sideNavOrder ? 1 : -1));
  return navRoutes;
}

const INACTIVITY_TIMEOUT_TIME = 15 * 60 * 1000;
const INACTIVITY_WARNING_TIMEOUT_TIME = 60 * 1000;

const PrivateRoute = ({ component: Component, exact, path, amwellStatus, ...rest }: RouteData) => {
  const hasDeps = useSelector(hasFetchedDependencies);
  const dispatch = useDispatch();
  const crumbs = useRoutesToCrumbs(privateRoutes.concat(publicRoutes));

  if (Component === ProviderSearch) {
    Component = KyruusProviderSearch;
  }

  if (!hasDeps) {
    return (
      <Redirect
        to={{
          pathname: '/loading',
          search: `redirect=${window.location.pathname}${window.location.search}`
        }}
      />
    );
  }

  if (rest.hideDefaultLayout) {
    return <Route exact={exact} path={path} component={Component} />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={props => (
        <ReactInAppUserActivity
          duration={INACTIVITY_TIMEOUT_TIME}
          warningDuration={INACTIVITY_WARNING_TIMEOUT_TIME}
          onLogout={() => dispatch(logout())}
          onTimeout={() => {
            window.localStorage.setItem('timedOut', 'true');
            dispatch(logout());
          }}
        >
          <ConnectCareActivity>
            <AuthLayout hidden={amwellStatus > 1}>
              <AppBanner position="top" hidden={false} style={{ zIndex: 10 }} />
              {rest.includeCrumbs ? (
                <Breadcrumbs crumbs={crumbs.filter(r => r.to !== '/landing')} />
              ) : null}
              {rest.includeBanner ? (
                <Banner
                  message={
                    props.match.params.title ? startCase(props.match.params.title) : rest.title
                  }
                  icon={rest.icon || null}
                  {...rest}
                  {...props}
                />
              ) : null}
              <Component {...rest} {...props} />
            </AuthLayout>
          </ConnectCareActivity>
        </ReactInAppUserActivity>
      )}
    />
  );
};

const PublicRoute = ({ component: Component, exact, path, ...rest }: RouteData) => {
  const screenText = useLanguageSwitcher(translations);
  const crumbs = useRoutesToCrumbs(publicRoutes);
  const labelKey = rest.title;

  if (!rest.includeDefaultLayout) {
    return <Route exact={exact} path={path} component={Component} />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={props => (
        <AuthLayout hidden={false} isGuest>
          <AppBanner />
          {rest.includeCrumbs ? (
            <Breadcrumbs crumbs={crumbs.filter(r => r.to !== '/landing')} />
          ) : null}
          {rest.includeBanner ? (
            <Banner
              message={
                props.match.params.title
                  ? startCase(screenText[props.match.params.title])
                  : screenText[labelKey]
              }
              icon={rest.icon || null}
              {...rest}
              {...props}
            />
          ) : null}
          <Component {...rest} {...props} />
        </AuthLayout>
      )}
    />
  );
};

const LandingResolver = () => {
  const hasDeps = useSelector(hasFetchedDependencies);
  const activeAccounts = useSelector(allActiveAccountsSelector);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isDfdAuthenticatedSelector);

  // If coming from the booking guest flow
  if (window.sessionStorage.getItem('provider') && isAuthenticated) {
    const provider = JSON.parse(window.sessionStorage.getItem('provider'));
    window.sessionStorage.removeItem('provider');
    dispatch(setDoctor(provider));
    dispatch(getCurrentSignedInUserPrimaryDoctor());

    if (window.sessionStorage.getItem('isExternalRequest')) {
      dispatch(setIsExternalRequest(true));
      window.sessionStorage.removeItem('isExternalRequest');
    }

    // Skip the booking patient select if no grantors
    if (hasDeps && activeAccounts.length === 1) {
      dispatch(setPatient(activeAccounts[0]));
      return <Redirect to="/u/get-care-now/booking/visit-type-select" />;
    }
    return <Redirect to="/u/get-care-now/booking/patient-select" />;
  }

  if (hasDeps && activeAccounts.length > 1) {
    return <Redirect to="/u/account-select" />;
  }

  if (hasDeps) {
    dispatch(getCurrentSignedInUserPrimaryDoctor());
    return <Redirect to="/u/dashboard" />;
  }

  return <Redirect to="/landing" />;
};

interface Props {
  navState?: NavStateEnum;
  amwellStatus?: number;
  profileLoading?: boolean;
  prediabetesEnabled?: boolean;
}
export const AppSwitchNavigatorComponent = ({
  navState,
  amwellStatus,
  prediabetesEnabled
}: Props) => {
  const location = useLocation();
  const selectedLanguage = useSelector(languageSelector);
  const { pathname, search } = location;
  const routeResolved = navState === 'authenticated' || navState === 'unauthenticated';

  const hasHealthtools = privateRoutes.some(route => route.path === '/u/health-tools/mystrength');
  if (!hasHealthtools) {
    const healthToolsRoutes = getScreensOfHealthTools(prediabetesEnabled);
    privateRoutes.push(...healthToolsRoutes);
  }

  const localeMap = {
    en: enLocale,
    es: esLocale
  };

  return (
    <MuiPickersUtilsProvider utils={DayJSUtils} locale={localeMap[selectedLanguage]}>
      <NavState pathname={`${pathname}${search}`}>
        {!routeResolved ? (
          <Loading />
        ) : (
          <Switch>
            {loadingRoutes.map(r => (
              <Route
                exact={r.exact ? r.exact : false}
                key={r.path}
                path={r.path}
                component={r.component}
              />
            ))}
            {navState === 'authenticated'
              ? privateRoutes.map(r => (
                  <PrivateRoute
                    exact={r.exact ? r.exact : false}
                    key={r.path}
                    path={r.path}
                    component={r.component}
                    amwellStatus={amwellStatus}
                    includeBanner={r.includeBanner ? r.includeBanner : true}
                    includeCrumbs={r.includeCrumbs ? r.includeCrumbs : true}
                    extraBannerPadding={r.extraBannerPadding}
                    {...r}
                  />
                ))
              : null}
            {routeResolved
              ? publicRoutes.map(r => (
                  <PublicRoute exact={r.exact ? r.exact : false} key={r.path} {...r} />
                ))
              : null}
            {routeResolved ? (
              <Route path="*">
                <LandingResolver />
              </Route>
            ) : null}
          </Switch>
        )}
        <Snacks />
        <ConfirmDialog />
        <CustomModal />
      </NavState>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  navState: currentNavigationWebStateSelector(state),
  amwellStatus: activityStatusSelector(state),
  prediabetesEnabled: isFeatureFlagOnSelector(state)('PREDIABETES_HEALTH_TOOL_ENABLED')
});

export default connect(mapStateToProps)(AppSwitchNavigatorComponent);
